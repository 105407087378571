<template>
  <v-container fluid>
    <v-app-bar fixed app color="blue-grey darken-3" dense dark>
      <v-btn icon to="/Manage">
        <v-icon large>mdi-arrow-left-thin-circle-outline</v-icon>
      </v-btn>
      <v-toolbar-title>จัดการงวด</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-layout>
      <v-row>
        <v-col>
          <v-radio-group v-model="rdoStatus">
            <p class="font-weight-black">เปิด-ปิด ทำงาน :</p>
            <v-radio color="red" label="เปิด" :value="1">
              <template v-slot:label>
                <div>
                  <strong class="green darken-1 blue-grey--text text--lighten-5">เปิด ทำงาน</strong>
                </div>
              </template>
            </v-radio>
            <v-radio color="red" label="ปิด" :value="0">
              <template v-slot:label>
                <div>

                  <strong class="red blue-grey--text text--lighten-5">หยุด ทำงาน</strong>
                </div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group v-model="rdoSwapIP">
            <p class="mt-2 font-weight-black">IP :</p>
            <v-radio color="green" :value="0">
              <template v-slot:label>
                <div>
                  <strong class="green--text text--accent-1
">IP</strong><strong class="green--text text--accent-1">
                    ซ้ำกันได้ (ในการแทง 1 รอบ)</strong>
                </div>
              </template></v-radio>
            <v-radio :value="1">
              <template v-slot:label>
                <div>
                  <strong class="green--text text--accent-1
">IP</strong>
                  <strong class="blue--text text--accent-1">
                    ไม่ซ้ำกัน (ในการแทง 1 รอบ)</strong>
                </div>
              </template></v-radio>

          </v-radio-group>

          <!-- <v-radio-group v-model="rdoDuplicateIP">
            <p class="mt-2 font-weight-black">สลับ IP :</p>
            <v-radio color="green" :value="0">
              <template v-slot:label>
                <div><strong class="green--text text--accent-1
">ไม่เปลี่ยน IP</strong>
                  <strong class="blue--text text--accent-1">
                    ใช้ IP ซ้ำได้เรื่อยๆ</strong>

                </div>
              </template></v-radio>
            <v-radio :value="1">
              <template v-slot:label>
                <div>
                  <strong class="green--text text--accent-1
">เปลี่ยน IP</strong><strong class="green--text text--accent-1">
                    หลังจาก IP นี้ได้โบนัส</strong>
                </div>
              </template></v-radio>

          </v-radio-group> -->

          <!-- rdoDuplicateIP -->
          <v-radio-group v-model="rdoSwapID">
            <p class="mt-2 font-weight-black">สลับไอดี :</p>
            <v-radio color="green" :value="0">
              <template v-slot:label>
                <div>
                  <strong class="green--text text--accent-1
">1 ไอดี</strong><strong class="green--text text--accent-1">
                    ได้โบนัสครั้งเดียวต่อวัน</strong>
                </div>
              </template></v-radio>
            <v-radio :value="1">
              <template v-slot:label>
                <div>
                  <strong class="green--text text--accent-1
">1 ไอดี</strong>
                  <strong class="blue--text text--accent-1">
                    ได้โบนัสเรื่อยๆ</strong>
                </div>
              </template></v-radio>
          </v-radio-group>

          <v-radio-group v-model="rdoTest">
            <p class="mt-2">ทดสอบ :</p>
            <v-radio color="warning" :value="0">
              <template v-slot:label>
                <div>
                  <strong class="orange--text text--dark-1
">ใช้จริง</strong>
                  <strong class="orange--text text--accent-1">
                    ไม่ทดสอบ</strong>
                </div>
              </template></v-radio>
            <v-radio color="warning" :value="1">
              <template v-slot:label>
                <div>
                  <strong class="orange--text text--dark-1
">ทดสอบ</strong>
                  <strong class="orange--text text--accent-1">
                    เต็มระบบ ไม่แทงเงิน</strong>
                </div>
              </template></v-radio>
            <v-radio color="warning" :value="2">
              <template v-slot:label>
                <div>
                  <strong class="orange--text text--dark-1
">ทดสอบยิง</strong>
                  <strong class="orange--text text--accent-1">
                    ไม่สน ip, ไม่จับคู่, ไม่แทงเงิน</strong>
                </div>
              </template></v-radio>
          </v-radio-group>
          <v-btn @click="btnSave_Click()" class="text-none green--text text--accent-2 font-weight-black"
            color="medium-emphasis" min-width="92" rounded variant="outlined">
            <v-icon dark left>mdi-check</v-icon>
            ตกลง
          </v-btn>
        </v-col>
      </v-row>

      <!-- <v-btn-toggle v-model="text" tile color="pink accent-1" group>
          <v-btn x-large value="Open">
            Open
          </v-btn>

          <v-btn x-large value="Close">
            Close
          </v-btn>

          <v-btn x-large value="Test">
            Test
          </v-btn>
        </v-btn-toggle> -->
    </v-layout>
  </v-container>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

export default {
  name: "Round_Setting",
  mixins: [globalFunctionMixin],

  mounted() {
    this.ckLogin();
    // this.$refs.b_nav.SetID(0);
    window.scrollTo(0, 0);
    this.fetch_data();
  },
  methods: {
    async fetch_data() {
      //   console.log(this.Roomname);
      let credentials = {
        WebName: this.WebName,
        Roomname: this.Roomname,
      };
      console.log(credentials);
      await this.$store.dispatch("web/get_room_detail", credentials).then(
        (response) => {
          console.log("STATUS");
          console.log(response.RoomData);
          console.log(response.RoomData.Status);
          this.rdoStatus = parseInt(response.RoomData.Status);
          this.rdoSwapIP = parseInt(response.RoomData.isSwapIP);
          this.rdoDuplicateIP = parseInt(response.RoomData.isDuplicateIP);
          this.rdoSwapID = parseInt(response.RoomData.isSwapID);
          this.rdoTest = parseInt(response.RoomData.isTest);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    btnSave_Click() {
      this.OnSave_RoomStatus();
    },
    async OnSave_RoomStatus() {
      let credentials = {
        WebName: this.WebName,
        Roomname: this.Roomname,
        SwapIP: this.rdoSwapIP,
        DuplicateIP: this.rdoDuplicateIP,
        SwapID: this.rdoSwapID,
        Test: this.rdoTest,
        Status: this.rdoStatus,
        Method: "update_status",
      };
      await this.$store.dispatch("web/set_room_detail", credentials).then(
        (response) => {
          //   console.log(response.RoomData.Status);
          this.$alert("", "บันทึกข้อมูลเรียบร้อย", "success");
        },
        (error) => {
          this.$alert("", "บันทึกข้อมูลผิดพลาด", "error");
          console.log(error);
        }
      );
    },
  },
  data: () => ({
    text: "center",
    rdoStatus: 0,
    rdoSwapIP: 0,
    rdoSwapID: 0,
    rdoTest: 0,
    rdoDuplicateIP: 0,
    WebName: sessionStorage.getItem("WebName"),
    Roomname: sessionStorage.getItem("Roomname"),
  }),
};
</script>

<style></style>

export default {
  methods: {
    ckLogin() {
      if (
        this.$headers["headers"]["Authorization"] !=
        "Bearer " + localStorage.getItem("blog_token")
      ) {
        this.$headers["headers"]["Authorization"];
        location.reload();
      }

      if (sessionStorage.getItem("UserID") == null) {
        this.$router.push("/");
      }
    },
  },
};
